// Import
@import './variables';

// Mixins
// --------------------------------------------------

// Responsive Utility
// --------------------------------------------------
@mixin respond($class) {
   @if $class==xs {
      @media (max-width: 480px) {
         @content;
      }
   } @else if $class==sm {
      @media (max-width: 768px) {
         @content;
      }
   } @else if $class==md {
      @media (max-width: 992px) {
         @content;
      }
   } @else if $class==lg {
      @media (max-width: 1200px) {
         @content;
      }
   } @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
   }
}

// Transition mixin with prefers-reduced-motion fallback
// --------------------------------------------------
@mixin transition($transition...) {
   transition: $transition;

   .prefers-reduced-motion & {
      transition: none;
   }
}

// Placeholder mixin with optional-at-root
// --------------------------------------------------
@mixin optional-at-root($sel) {
   @at-root #{if(not &, $sel, selector-append(&, $sel))} {
      @content;
   }
}

@mixin placeholder {
   @include optional-at-root('::-webkit-input-placeholder') {
      @content;
   }

   @include optional-at-root(':-moz-placeholder') {
      @content;
   }

   @include optional-at-root('::-moz-placeholder') {
      @content;
   }

   @include optional-at-root(':-ms-input-placeholder') {
      @content;
   }
}

// Aspect ratio mixin
// --------------------------------------------------
// example: @include ratio(640, 320);
// use custom values to calculate a custom ratio.

@mixin ratio($width: false, $height: false) {
   position: relative;
   display: block;
   width: 100%;
   padding: 0;
   overflow: hidden;

   &:before {
      content: "";
      display: block;
      @if ($width or $height) {
         padding-top: ($height / $width) * 100%;
      }
   }

   &-item,
   embed,
   object,
   video,
   iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
   }
}

// Absolute positioning
// --------------------------------------------------
// example: @include position(translateY);

@mixin position($position) {
   position: absolute;

   // Position -- center / center
   @if ($position == 'translate') {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   // Position -- center X
   @if ($position == 'translateX') {
      left: 50%;
      transform: translateX(-50%);
   }

   // Position -- center Y
   @if ($position == 'translateY') {
      top: 50%;
      transform: translateY(-50%);
   }

   // Position -- RESET
   @if ($position == 'reset') {
      position: relative;
      top: auto;
      left: auto;
      transform: translate(0, 0);
   }
}

// Fonts
// --------------------------------------------------

// Font
@mixin font($style: null, $swap: null) {
   font-weight: normal;
   line-height: 1.45;

   @if ($style == h1) {
      @if ($swap == swap) {
         color: $brand-primary !important;
      } @else {
         color: $brand-secondary !important;
      }
      font-family: $font-family-medium !important;
      font-size: $font-size-h1;

      @include respond(md) {
         font-size: $font-size-h1-mobile;
      }
   } @else if ($style == h2) {
      @if ($swap == swap) {
         color: $brand-primary !important;
      } @else {
         color: $brand-secondary !important;
      }
      font-family: $font-family-light !important;
      font-size: $font-size-h2;

      @include respond(md) {
         font-size: $font-size-h2-mobile;
      }
   } @else if ($style == h3) {
      @if ($swap == swap) {
         color: $brand-secondary !important;
      } @else {
         color: $brand-primary !important;
      }
      font-family: $font-family-medium !important;
      font-size: $font-size-h3;

      @include respond(md) {
         font-size: $font-size-h3-mobile;
      }
   } @else if ($style == h4) {
      @if ($swap == swap) {
         color: $brand-secondary !important;
      } @else {
         color: $brand-primary !important;
      }
      font-family: $font-family-light !important;
      font-size: $font-size-h4;

      @include respond(md) {
         font-size: $font-size-h4-mobile;
      }
   } @else if ($style == h5) {
      @if ($swap == swap) {
         color: $brand-primary !important;
      } @else {
         color: $brand-secondary !important;
      }
      font-family: $font-family-medium !important;
      font-size: $font-size-h5;

      @include respond(md) {
         font-size: $font-size-h5-mobile;
      }
   } @else if ($style == t1) {
      color: $brand-primary !important;
      font-family: $font-family-light !important;
      font-size: $font-size-t1;

      @include respond(md) {
         font-size: $font-size-t1-mobile;
      }
   } @else if ($style == l1) {
      color: $brand-secondary !important;
      font-family: $font-family-medium !important;
      font-size: $font-size-l1;

      @include respond(md) {
         font-size: $font-size-l1-mobile;
      }
   } @else if ($style == legacy) {
      font-family: $font-family-medium !important;
      font-size: 16px;
   }
}
