// Fonts
@font-face {
    font-family: 'CordaLig';
    src: url('../../fonts/CordaLig-webfont.woff2') format('woff2'),
         url('../../fonts/CordaLig-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CordaMed';
    src: url('../../fonts/CordaMed-webfont.woff2') format('woff2'),
         url('../../fonts/CordaMed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CordaBol';
    src: url('../../fonts/CordaBol-webfont.woff2') format('woff2'),
         url('../../fonts/CordaBol-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
